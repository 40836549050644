import React, { useState, useEffect, useContext } from 'react';
import Layout from 'components/layout';
import SEO from 'components/seo';
import styled, { css } from 'styled-components';
import { device } from 'components/device';

// Components
import { FeaturedMarketing } from 'components/CmsComponents/FeaturedMarketing';
import Components from 'components/CmsComponents/';
import ListPosts from 'components/blog/listPosts';

// Context
import { HeaderContext } from 'context/header';

const GridContainer = styled.div`
  display: ${props => (props.isDesktop ? 'grid' : 'flex')};
  grid-template-columns: ${props =>
    props.isDesktop && 'auto repeat(12, 70px) auto'};
  column-gap: ${props => props.isDesktop && '30px'};
  flex-direction: ${props => !props.isDesktop && 'column'};
  padding: ${props => !props.isDesktop && '20px'};

  @media ${device.laptop} {
    flex-direction: column;
    padding-bottom: 0;
    padding-top: 0;
    margin-top: 0;
    margin-bottom: 0;
  }
`;

const Part = styled.div`
  grid-column: ${props => props.gridColumn};

  @media ${device.laptop} {
    flex-direction: column;
    margin-top: 0;
    margin-bottom: ${props => (props.isFitCardButtonPages ? '1.875rem' : '0')};
    padding-bottom: ${props => props.borderBottom && '1.875rem'};
    border-bottom: ${props => props.borderBottom && '1px solid #C6C8CC'};
  }

  ${props =>
    props.padding &&
    css`
      padding: 4.375rem 0;

      @media ${device.laptop} {
        padding: 1.875rem 0;
      }
    `}

  ${props =>
    props.borderTop &&
    css`
      border-top: 1px solid #c6c8cc;
    `}
`;

const SpecialtiesCardio = ({ pageContext }) => {
  const [isDesktop, setDesktop] = useState(true);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setDesktop(window.innerWidth > 1024);
    }
  }, []);

  const {
    setMainMarginTopLaptop,
    setMainMarginTopMobile,
    showAlertPostFeriado,
    isVisibleNovoSite,
  } = useContext(HeaderContext);

  useEffect(() => {
    if (showAlertPostFeriado || isVisibleNovoSite) {
      setMainMarginTopLaptop('9.075rem');
      setMainMarginTopMobile('7.875rem');
    } else {
      setMainMarginTopLaptop('7.063rem');
      setMainMarginTopMobile('5.875rem');
    }
  }, [showAlertPostFeriado, isVisibleNovoSite]);

  const {
    name,
    seo,
    featuredMarketing,
    components,
    qa,
    customURLBlog,
    posts,
    slug,
  } = pageContext;

  const formattedSeo = seo && [{ seo }];

  const dataSeo = [
    {
      seo: {
        metaTitle: name,
        metaDescription: name,
      },
    },
  ];

  return (
    <Layout>
      <SEO dataSeo={formattedSeo || dataSeo} />
      {featuredMarketing && <FeaturedMarketing content={featuredMarketing} />}
      {components.length >= 1 &&
        components?.map(component => {
          return Components(component);
        })}
      <GridContainer isDesktop={isDesktop}>
        {posts?.length > 0 && (
          <Part gridColumn={'2 / -2'} padding>
            <ListPosts
              title="Blog do Sírio-Libanês"
              postsToShow={3}
              posts={posts}
              headerLink={`/${customURLBlog ?? 'blog/'}`}
            />
          </Part>
        )}
      </GridContainer>
    </Layout>
  );
};

export default SpecialtiesCardio;
